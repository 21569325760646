;
$(function () {
  'use strict'

  var $body = $('body')

  $('.js-tech-type-menu-handler').each(function () {
    var $handler = $(this)
    $handler.multilevelMenu({
      container   : $handler.closest('.tech-type__menu-container'),
      upper_corner: true
    })
  })

  $body
    .on('click', '.tech-type__menu-header-link', function (event) {
      event.preventDefault()

      $(this).closest('.tech-type__menu').toggleClass('tech-type__menu_state_opened')
    })

    .on('click', '.js-tech-type-menu-toggle', function (event) {
      event.preventDefault()
      event.stopImmediatePropagation()

      var $handler = $(this)
      var $ttype = $handler.closest('.tech-type__item')

      $ttype.find('.js-tech-type-menu-handler').trigger('click')
    })
})
